import * as React from 'react'

import CriteriaIcon from '../utils/criteriaIcon'

import criterias from '../json/criterias.json'
import sectors from '../json/sectors.json'

const Noteindicator = ({currentCriteria, currentSector, globalAverage, sectorAverage}) => {
  return (
    <div className="note-indicator">
      <div className="note-indicator__side note-indicator__item text-old-pink-darker">
        {CriteriaIcon(currentCriteria, 'icon-old-pink')}
        <p className="label">{criterias[currentCriteria]}</p>
      </div>
      <div className="note-indicator__side">
        { currentSector !== 'all' &&
          <div className={`note-indicator__item bg-${currentSector}`}>
            <p className="label mb-6 md:mb-7 lg:mb-9">{sectors[currentSector]}</p>
            <p className="number-big">{sectorAverage}</p>
          </div>
        }
        <div className={`note-indicator__item ${currentSector !== 'all' ? 'text-old-pink-darker' : ''}`}>
          <p className="label mb-6 md:mb-7 lg:mb-9">{currentSector === 'all' ? 'Index moyen' : 'Tous secteurs'}</p>
          <p className="number-big">{globalAverage}</p>
        </div>
      </div>
    </div>
  )
}

export default Noteindicator