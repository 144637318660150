import * as React from 'react'

const BrandCardLineSummary = ({brand, criteria, number}) => {
  const {cat, global, usability, logo, accessibility, seo, conception } = brand[1]

  const getNote = (criteria) => {
    switch(criteria) {
      case 'global':
        return global
      case 'usability':
        return usability
      case 'seo':
        return seo
      case 'conception':
        return conception
      case 'accessibility':
        return accessibility
      default:
        return null
    }
  }

  const getEvol = (criteria) => {
    switch(criteria) {
      case 'global':
        return brand[1]['evol_global']
      case 'usability':
        return brand[1]['evol_usability']
      case 'seo':
        return brand[1]['evol_seo']
      case 'conception':
        return brand[1]['evol_conception']
      case 'accessibility':
        return brand[1]['evol_accessibility']
      default:
        return null
    }
  }
  
  return (
    <div className={`brand-card-line bg-${cat} ${brand[1]['evol_global'] ? '' : 'no-arrow'}`}>
      <div className="brand-card-line__inner flex items-center">
        <p className="knockout-title brand-card-line__number">{number}</p>

        <div className="brand-card-line__logo">
          <img src={`/images/logos/${logo}`} alt={brand[0]} />
        </div>
        
        { getNote(criteria) !== null &&
          <p className={`brand-card-line__note ${brand[1]['evol_global'] ? `brand-card-line__note--${getEvol(criteria)}` : ''} is-active`}>{getNote(criteria)}</p>
        }
      </div>
    </div>
  )
}

export default BrandCardLineSummary