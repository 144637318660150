import React, {useRef, useState, useEffect} from 'react'
import {useEventListener} from 'react-recipes'

import texts2022 from '../json/texts.json'
import texts2021 from '../json/2021/texts.json'
import Noteindicator from './noteIndicator'

import criterias from '../json/criterias.json'
import sectors from '../json/sectors.json'

import orderBrands from '../utils/orderBrands'
import BrandCardLineSummary from './brandCardLineSummary'

import getOffsetEl from '../utils/getOffsetEl'

const RankingContent = ({currentCriteria, currentSector, isSinglePage = false, isIndex = false, year = '2022', noText = false}) => {
  let currentScore = useRef(0)
  const [stickySidebar, setStickySidebar] = useState(false)
  const [bottomedSidebar, setBottomedSidebar] = useState(false)
  const [sidebarOffset, setSidebarOffset] = useState(0)
  const [sidebarHeight, setSidebarHeight] = useState(0)
  const [sidebarContentHeight, setSidebarContentHeight] = useState(0)
  const [sidebarWidth, setSidebarWidth] = useState('auto')
  const sidebar = useRef(null)
  const sidebarContent = useRef(null)
  const [reload, setReload] = useState(false)
  const [texts, setTexts] = useState(year === '2021' ? texts2021 : texts2022)

  const articles = {
    "beauty": "la ",
    "luxury": "le ",
    "car": "l'",
    "telecom": "les ",
    "specialized": "la ",
    "supermarket": "la "
  }

  let {globalAverage, sectorAverage, orderedBrands} = orderBrands(currentCriteria, currentSector, year)

  if(isIndex) {
    orderedBrands = orderedBrands.slice(0,5)
  }

  const handleCalcs = () => {
    if (!isSinglePage && sidebar.current) {
      setSidebarOffset(getOffsetEl(sidebar.current))
      setSidebarHeight(sidebar.current.offsetHeight)
      setSidebarWidth(sidebar.current.offsetWidth)
      setSidebarContentHeight(sidebarContent.current.offsetHeight)
    }
  }

  const handleScroll = () => {
    if  (window.innerWidth >= 1024 && !isSinglePage ) {
      if(window.pageYOffset + 80 > sidebarOffset && window.pageYOffset + 80 < sidebarOffset + sidebarHeight - sidebarContentHeight) {
        setStickySidebar(true)
      } else {
        if (window.pageYOffset + 80 > sidebarOffset + sidebarHeight - sidebarContentHeight) {
          setBottomedSidebar(true)
        } else {
          setBottomedSidebar(false)
        }

        setStickySidebar(false)
      }
    }
  }

  const renderRanking = (pos) => {
    return (
      <>
        <h3 className={`knockout-title knockout-title--small ${pos === 'left' ? 'mt-8' : 'lg:mt-14 xl:mt-0'}`}>Classement</h3>

        <ul className={`${pos === 'left' ? 'mb-12 md:mb-32' : 'mb-4 md:mb-4'} lg:mb-0`}>
        { orderedBrands.map((brand, i) => {
          let hasNumber = true
          if (currentScore.current !== brand[1][currentCriteria]) {
            currentScore.current = brand[1][currentCriteria]
          } else {
            hasNumber = false
          }

          return (
            <li key={i} className="mb-2 md:mb-4">
              <BrandCardLineSummary
                brand={brand} criteria={currentCriteria} number={hasNumber ? i+1 : '-'}
              />
            </li>
          )
        })}
        </ul>
      </>
    )
  }

  useEffect(() => {
    if( !isSinglePage) {
      handleCalcs()

      setTimeout(() => {
        setReload(true) // force first repaint otherwise miscalculation
      }, 750)
    }

    setTexts(year === '2021' ? texts2021 : texts2022)
  /* eslint-disable-next-line */
  }, [currentCriteria, currentSector, reload, year])

  useEventListener('scroll', handleScroll)
  useEventListener('resize', handleCalcs)

  return (
    <>
      { texts &&
        <div className={`lg:flex justify-between text-half ${ !isSinglePage ? 'mt-10 md:mt-0' : ''}`} >
          <div className="text-half__item" ref={sidebar}>
            <div ref={sidebarContent} className={`${!isSinglePage ? 'sidebar-content' : ''} ${stickySidebar ? 'is-sticky': ''} ${bottomedSidebar ? 'is-bottomed': ''}`} style={{width: sidebarWidth}}>
              { currentSector === 'all' ?
                <>
                  { isSinglePage ?
                    <h2 className="title-section mb-10 md:mb-9">{texts['all'][currentCriteria]['titleSingle']}</h2>
                    : <p className="emphasis mb-10 md:mb-9" dangerouslySetInnerHTML={{ __html: texts['all'][currentCriteria]['intro'] }}></p>
                  }
                </>
              : <>
                  { isSinglePage ? 
                    <h2 className="title-section mb-10 mb:mb-6">L'<span className="font-medium text-old-pink">{criterias[currentCriteria]}</span> dans {articles[currentSector]}<span className="font-medium">{sectors[currentSector]}</span></h2>
                    : <p className="emphasis mb-10 mb:mb-6">L'<span className="font-medium text-old-pink">{criterias[currentCriteria]}</span><br/> dans {articles[currentSector]}<span className="font-medium">{sectors[currentSector]}</span></p>
                  }
                </> 
              }
              <Noteindicator
                globalAverage={globalAverage}
                sectorAverage={sectorAverage}
                currentSector={currentSector}
                currentCriteria={currentCriteria} />
              </div>
              
              { isSinglePage && !noText &&
                renderRanking('left')
              }
          </div>
    
          <div className="text-half__item mt-8 lg:mt-0 md:pb-4">
            {!noText
              ? <>
                  <h3 className="knockout-title knockout-title--small text-right md:-mt-21 md:mb-2 md:-mr-8">Analyse</h3>
                  <div className="paragraph paragraph--indented" dangerouslySetInnerHTML={{ __html: texts[currentSector][currentCriteria]['txt'] }}></div>
                </>
              : renderRanking('right')
            }
          </div>
        </div>
      }
    </>
  )
}

export default RankingContent